
button:focus {
  outline: none !important;
}

$btn-custom-color:              #fff !default;
$btn-custom-bg:                 $custom !default;
$btn-custom-border:             $btn-custom-bg !default;

$btn-dark-color:              #fff !default;
$btn-dark-bg:                 $dark !default;
$btn-dark-border:             $btn-dark-bg !default;

$btn-pink-color:              #fff !default;
$btn-pink-bg:                 $pink !default;
$btn-pink-border:             $btn-pink-bg !default;

$btn-purple-color:              #fff !default;
$btn-purple-bg:                 $purple !default;
$btn-purple-border:             $btn-purple-bg !default;


.btn-custom {
  @include button-variant($btn-custom-color, $btn-custom-bg, $btn-custom-border);
}

.btn-pink {
  @include button-variant($btn-pink-color, $btn-pink-bg, $btn-pink-border);
}

.btn-dark {
  @include button-variant($btn-dark-color, $btn-dark-bg, $btn-dark-border);
}

.btn-purple {
  @include button-variant($btn-purple-color, $btn-purple-bg, $btn-purple-border);
}

// Outline buttons
.btn-dark-outline {
  @include button-outline-variant($btn-dark-bg);
}

.btn-pink-outline {
  @include button-outline-variant($btn-pink-bg);
}

.btn-purple-outline {
  @include button-outline-variant($btn-purple-bg);
}


.btn-rounded {
    border-radius: 2em !important;
    padding: 0.375rem 1.3rem;
}

.btn-label {
  background: rgba($dark, 0.1);
  display: inline-block;
  padding: 7px 15px;
  border-radius: 3px 0 0 3px;
  margin: -7px -14px;
  margin-right: 12px;
}

.btn-rounded .btn-label {
  padding: 7px 15px 7px 20px;
  margin-left: -20px;
}

.btn-rounded .btn-label-right {
  margin-right: -20px;
  margin-left: 12px;
}

.btn-label-right {
  margin-left: 12px;
  margin-right: -13px;
  border-radius: 0px 3px 3px 0px;
}

/* Social Buttons */

.btn-facebook {
    color: $white !important;
    background-color: #3b5998 !important;
}
.btn-twitter {
    color: $white !important;
    background-color: #00aced !important;
}
.btn-linkedin {
    color: $white !important;
    background-color: #007bb6 !important;
}
.btn-dribbble {
    color: $white !important;
    background-color: #ea4c89 !important;
}
.btn-googleplus {
    color: $white !important;
    background-color: #dd4b39 !important;
}
.btn-instagram  {
    color: $white !important;
    background-color: #517fa4 !important;
}
.btn-pinterest  {
    color: $white !important;
    background-color: #cb2027 !important;
}
.btn-dropbox  {
    color: $white !important;
    background-color: #007ee5 !important;
}
.btn-flickr  {
    color: $white !important;
    background-color: #ff0084 !important;
}
.btn-tumblr  {
    color: $white !important;
    background-color: #32506d !important;
}
.btn-skype {
    color: $white !important;
    background-color: #00aff0 !important;
}
.btn-youtube  {
    color: $white !important;
    background-color: #bb0000 !important;
}
.btn-github  {
    color: $white !important;
    background-color: #171515 !important;
}

