// Custom Input Paddings
$input-padding-x: .75rem !default;
$input-padding-y: .75rem !default;

// Custom Color for Buttons
$btn-success-color:   #FFF;
$btn-success-bg:      #1fb0f0;
$btn-success-border:  #1fb0f0;

// Custom Vars
$custom:              #64b0f2;