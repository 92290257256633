
@import "variables";


/*
Template Name: Uplon Dashboard
Author: CoderThemes
Email: coderthemes@gmail.com
File: Menu
*/

.topbar {

  left: 0px;
  position: fixed;
  right: 0;
  top: 0px;
  z-index: 999;

  .topbar-left {
    background: $white;
    float: left;
    text-align: center;
    height: 70px;
    position: relative;
    width: 250px;
    z-index: 1;

    .logo {
      line-height: 70px;
    }
  }
}

.navbar-custom {
  background-color: $dark;
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0px 20px;
  margin-left: 250px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);

  .navbar-nav .nav-link {
    padding: 0px;
    line-height: 70px;
    color: rgba($white,0.6);
  }
}


.logo {
  color: $dark !important;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;

  span {
    span {
      color: $custom;
    }
  }
}

.user-box {
  text-align: center;
  padding: 30px 0px 20px 0px;

  .user-img {
    position: relative;
    height: 88px;
    width: 88px;
    margin: 0px auto;

  }

  h5{
    a{
      color: $gray-dark;
    }
  }

  .user-status {
    height: 12px;
    width: 12px;
    position: absolute;
    bottom: 7px;
    right: 15px;

    i {
      font-size: 15px;
    }
  }
  .user-status.away {
    i {
      color: $brand-warning;
    }
  }
  .user-status.offline {
    i {
      color: $brand-danger;
    }
  }
  .user-status.online {
    i {
      color: $brand-success;
    }
  }
  .user-status.busy {
    i {
      color: $gray-dark;
    }
  }

  ul {
    li {
      a{
        color: $gray-dark;

        &:hover {
          color: $custom;
        }
      }
    }
  }
}



.side-menu {
  top: 70px;
  width: 250px;
  z-index: 10;
  background: $white;
  bottom: 70px;
  margin-bottom: -70px;
  margin-top: 0px;
  padding-bottom: 70px;
  position: absolute;
  box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
}

.content-page {
  margin-left: 250px;
  overflow: hidden;


  .content {
    padding: 0 5px;
    margin-top: 73px;
  }
}

.side-menu.left {
    position: absolute;
}

body.fixed-left .side-menu.left {
    bottom: 50px;
    margin-bottom: -70px;
    margin-top: 0px;
    padding-bottom: 70px;
    position: fixed;
}

.button-menu-mobile {
  background: transparent;
  border: none;
  color: $white;
  font-size: 21px;
  line-height: 68px;
  padding: 0px 15px;
  margin-left: -10px;

  &:hover {
    color: $custom;
  }
}

.sidebar-inner {
  height: 100%;
}

#sidebar-menu, #sidebar-menu ul, #sidebar-menu li, #sidebar-menu a {
  border: 0;
  font-weight: normal;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
}

#sidebar-menu {
  padding-bottom: 30px;
  width: 100%;

  a {
    line-height: 1.3;
  }

  ul {
    li {
      .menu-arrow {
        -webkit-transition: -webkit-transform .15s;
        -o-transition: -o-transform .15s;
        transition: transform .15s;
        position: absolute;
        right: 20px;
        display: inline-block;
        font-family: 'Material-Design-Iconic-Font';
        text-rendering: auto;
        line-height: 18px;
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);

        &:before {
          content: '\f2fb';
        }
      }
      a.subdrop .menu-arrow {
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
    ul {
      display: none;
      li {
        border-top: 0;
      }
      li.active {
        a {
          color: $custom;
        }
      }
      a {
        color: rgba($dark,0.8);
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        display: block;
        padding: 10px 20px 10px 60px;
        &:hover {
          color: $dark;
        }
        i {
          margin-right: 5px;
        }
      }
      ul {
        a {
          padding-left: 80px;
        }
      }
    }
  }
  .label {
    margin-top: 2px;
  }
  .subdrop {
    color: $dark !important;
  }
}


#sidebar-menu > ul > li > a {
  color: rgba($dark,0.8);
  display: block;
  padding: 14px 20px;
  font-weight: 500;

  &:hover {
    color: $dark;
    text-decoration: none;
  }
}

#sidebar-menu > ul > li > a > span {
  vertical-align: middle;
}

#sidebar-menu {
  ul {
    li {
      a{
        i{
          display: inline-block;
          font-size: 18px;
          line-height: 17px;
          margin-left: 3px;
          margin-right: 15px;
          text-align: center;
          vertical-align: middle;
          width: 20px;
        }

        .drop-arrow {
          float: right;

          i{
            margin-right: 0px;
          }
        }
      }
    }
  }
}


#sidebar-menu > ul > li > a.active {
  color: $white !important;
  background-color: $custom;
}


.menu-title {
  padding: 12px 20px !important;
  letter-spacing: .035em;
  pointer-events: none;
  cursor: default;
  font-size: 13px;
}

.footer {
    border-top: 1px solid rgba(152, 166, 173, 0.2);
    bottom: 0px;
    color: $text-muted;
    text-align: left !important;
    padding: 19px 30px 20px;
    position: absolute;
    right: 0px;
    left: 250px;
}

.content-page > .content {
    margin-top: 70px;
    padding: 20px 8px 15px 8px;
}

#wrapper.enlarged {

  .menu-title {
    display: none;
  }
  .menu-arrow {
    display: none !important;
  }

  .footer {
    left: 70px;
  }

  #sidebar-menu {
    ul {
      ul {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 10px 0 rgba(0, 0, 0, 0.08);
      }
    }
  }
  .left.side-menu {
    width: 70px;
    z-index: 5;

    #sidebar-menu > ul > li > a {
      padding-left: 10px;
      &:hover {
        background-color: $custom;
      }
    }
    span.pull-right {
      display: none !important;
    }
    #sidebar-menu {
      ul > li {
        position: relative;
        white-space: nowrap;
        &:hover > a {
          background-color: $custom;
          position: relative;
          width: 260px;
        }
        &:hover > ul {
          display: block;
          left: 70px;
          position: absolute;
          width: 190px;
          padding: 5px 0px;
          background: $white;

          a {
            background: $white;
            border: none;
            box-shadow: none;
            padding-left: 15px;
            position: relative;
            width: 190px;
            z-index: 6;
            color: rgba($dark,0.8);

            &:hover {
              color: $custom;
            }
          }
        }
        &:hover {
          a {
            span {
              display: inline;
            }
          }
        }
      }
      ul > li > ul {
        display: none;
      }
      ul {
        ul {
          li {
            &:hover > ul {
              display: block;
              left: 190px;
              margin-top: -36px;
              position: absolute;
              width: 190px;
            }
          }
          li > a {
            span.pull-right {
              -ms-transform: rotate(270deg);
              -webkit-transform: rotate(270deg);
              position: absolute;
              right: 20px;
              top: 12px;
              transform: rotate(270deg);
            }
          }
        }
      }
      ul > li > a {
        span {
          display: none;
          padding-left: 10px;
        }
      }
    }
  }
  .left.side-menu #sidebar-menu > ul > li > a {
    padding: 15px 20px;
    min-height: 50px;
  }
  .left.side-menu #sidebar-menu > ul > li > a i {
    margin-right: 20px !important;
    font-size: 20px;
  }
  .left.side-menu #sidebar-menu ul > li:hover > a {
    position: relative;
    width: 260px;
    background-color: $custom;
    color: $white;

    i {
      color: $white;
    }
}
  .content-page {
    margin-left: 70px;
  }
  .navbar-custom {
    margin-left: 70px;
  }
  .topbar {
    .topbar-left {
      width: 70px !important;
      .logo {
        span {
          display: none;
          opacity: 0;
        }
        i {
          margin-right: 0px;
        }
      }
    }
  }
  #sidebar-menu > ul > li {
    &:hover > a.open {
      :after {
        display: none;
      }
    }
    &:hover > a.active {
      :after {
        display: none;
      }
    }
  }

  .user-detail {
    bottom: 0px;

    .dropup {
      margin: 0px auto;
      margin-left: 17px;
    }
    padding: 15px 0px;
    width: 70px;
    text-align: center;
    h5{
      display: none;
    }
    p {
      position: absolute;
      right: 15px;
      top: 32px;
      span{
        display: none;
      }
    }
  }
  #sidebar-menu ul ul li.active a {
    color: $brand-primary;
  }

}

#wrapper.right-bar-enabled {
  .right-bar {
    right: 0;
  }
  .left-layout {
    left: 0;
  }
}



/* Notification */
.notification-list {
  margin-left: 0 !important;

  .noti-title {
    border-radius: 0.25rem 0.25rem 0 0;
    background-color: $custom;
    margin: -6px -1px 0px -1px;
    width: auto;
    padding: 12px 20px;

    h5 {
      color: $white;
      margin: 0;
    }
  }

  .noti-icon {
    font-size: 22px;
    padding: 0 12px;
    vertical-align: middle;
    color: rgba($white,0.8);
  }
  .noti-icon-badge {
    height: 10px;
    width: 10px;
    background-color: $brand-danger;
    display: inline-block;
    position: absolute;
    top: 23px;
    right: 12px;
    border-radius: 50%;
    border: 2px solid $dark;
  }
  .notify-item {
    padding: 10px 20px;

    .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;

      img {
        margin-top: 4px;
      }
    }
    .notify-details {
      margin-bottom: 0;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap;
      b {
        font-weight: 500;
      }
      small {
        display: block;
      }
      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
    }
  }
  .notify-all {
    border-radius: 0 0 0.25rem 0.25rem;
    margin: 0 0 -5px 0;
    background-color: $gray-lighter;
  }
}

.profile-dropdown {
  width: 170px;
  i {
    font-size: 17px;
    vertical-align: middle;
    margin-right: 5px;
  }
  span {
    vertical-align: middle;
  }
}





.nav-user {
  padding: 0 12px !important;

  img {
    height:36px;
    width: 36px;
  }
}


/* Right sidebar */
.side-bar.right-bar {
  float: right !important;
  right: -266px;
  top: 71px;
}

.side-bar {
  -moz-transition: all 200ms ease-out;
  -webkit-transition: all 200ms ease-out;
  background-color: $white;
  box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  display: block;
  float: left;
  height: 100%;
  position: fixed;
  transition: all 200ms ease-out;
  width: 240px;
  z-index: 9;
}

.right-bar {
  background: $white !important;

  .nav-tabs {
    border: none !important;

    .nav-item {
      float: none;
      display: inline-block;
      margin-bottom: 0;
    }
    .nav-link {
      border: none !important;
      font-weight: 500;
      font-size: 16px;
      color: $dark;
    }
    .nav-link.active {
      color: $custom;
      background-color: transparent;
    }
  }

  .tab-content {
    padding: 20px;
    border-top: 2px solid $gray-lighter;
  }

  .right-bar-toggle {
    float: right;
    line-height: 46px;
    font-size: 20px;
    color: #333;
    padding: 0px 10px;
  }

  .nicescroll {
    height: 88%;
  }
  .contact-list {
    max-height: 600px;
  }

  h5{
    font-size: 15px;
  }
}


/* Seach */
.app-search {
  position: relative;

  a {
    position: absolute;
    top: 0;
    right: 5px;
    display: block;
    height: 34px;
    line-height: 34px;
    width: 34px;
    text-align: center;
    color: rgba($white, 0.5);

    &:hover {
      color: $white;
    }
  }

  .form-control,
  .form-control:focus {
    border: 1px solid rgba($gray-light, 0.3);
    font-size: 13px;
    height: 34px;
    color: $white;
    padding-left: 20px;
    padding-right: 40px;
    margin-top: 18px;
    background: rgba($gray-light, .1);
    box-shadow: none;
    border-radius: 30px;
    width: 200px;
  }
}



@media (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
  .enlarged {
    .left.side-menu {
      margin-left: -75px;
    }
  }
  .topbar-left {
    width: 70px !important;

    span {
      display: none !important;
    }
    i {
      display: block !important;
      line-height: 75px !important;
    }
  }

  .navbar-custom {
    margin-left: 70px !important;
  }

  .content-page {
    margin-left: 0px !important;
  }
  .footer {
    left: 0px !important;
  }

  .mobile-sidebar {
    left: 0px;
  }

  .mobile-content {
    left: 250px;
    right: -250px;
  }

  .wrapper-page {
    width: 90%;
  }

  .dataTables_wrapper {
    .col-xs-6 {
      width: 100%;
      text-align: left;
    }
  }

  div#datatable-buttons_info {
    float: none;
  }
  .ms-container {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .dropdown-lg {
    width: 200px;
  }
  .notification-list .notify-item {
    padding: 10px 15px;
  }
  .page-title-box {
    .page-title,.breadcrumb {
      float: none;
    }
    .page-title {
      margin-bottom: 10px;
    }
  }
}


@media (max-width: 480px) {
  .notification-list .noti-icon {
    font-size: 18px;
    padding: 0 10px;
  }

  .hidden-mobile {
    display: none !important;
  }
}

/* Body min-height set */
body.fixed-left-void {
  min-height: 1170px;
}
.mobile.smallscreen .sidebar-inner.slimscrollleft {
  overflow: scroll !important;
}





