/* =============
   Custom Styles
================ */

.help-block {
  color: $state-danger-text;
  font-size: 0.85rem;
}

.has-error {
  input {
    border-color: $state-danger-border;
  }

  ::-webkit-input-placeholder,
  ::-moz-placeholder,
  :-ms-input-placeholder,
  :-moz-placeholder {
    color: $state-danger-text;
  }
}

.login-logo {
  width: 60%;
  margin: 0 auto;
}

.topbar-left {
  img {
    width: 50%;
  }
}


#mainSliderForm {
  .jFiler-theme-default .jFiler-input {
    margin-bottom: 0;
  }
}

.editLogos .inputField {
  height: 160px;
}

.inputField__repeater {
  display: none;

  body.smallscreen & {
    img {
      width: 100%;
      max-width: 100%;
    }
  }

  img {
    width: 100%;
    max-width: 196px;
  }
}

.inputField__slide {
  &.centered {
    img {
      margin: 0 auto;
      display: block;
    }

    .edit {
      text-align: center;
    }
  }
}

.inputField__repeater:nth-child(1) {
  display: block;

  .removeSlide {
    display: none;
  }
}