
label {
  font-weight: 500;
}

.input-group-addon {
  font-size: 1.2rem;
  line-height: 1.5rem;
}


/* Bootstrap tagsinput */
.bootstrap-tagsinput {
  width: 100%;
  box-shadow: none;
  padding: 3px 7px 6px;
  border: 1px solid $gray-lighter;

  .label-info {
    background-color: $custom !important;
    display: inline-block;
    padding: 5px;
  }
}


// Multiple select
.search-input {
  margin-bottom: 10px;
}

.ms-container {
  background: transparent url('../dashboard/plugins/multiselect/img/multiple-arrow.png') no-repeat 50% 50%;
  width: 100% !important;
  max-width: 360px !important;

  .ms-list {
    box-shadow: none;
    border: 1px solid rgba($dark,0.2);
  }
  .ms-list.ms-focus {
    box-shadow: none;
    border: 1px solid rgba($dark,0.4);
  }
  .ms-selectable {
    box-shadow: none;
    outline: none !important;

    li.ms-elem-selectable{
      border: none;
      padding: 5px 10px;
    }
    li.ms-hover {
      background-color: $custom;
    }
  }
  .ms-selection {
    li.ms-elem-selection{
      border: none;
      padding: 5px 10px;
    }
    li.ms-hover {
      background-color: $custom;
    }
  }
}

/* Select 2 */
.select2-container {
  .select2-selection--single {
    height: 34px;
    border: 1px solid rgba($dark,0.3);
    outline: none !important;

    .select2-selection__rendered {
      line-height: 32px !important;
      padding-left: 12px;
    }
    .select2-selection__arrow {
      height: 32px;
      width: 30px;
    }
  }
}
.select2-container--default {
  .select2-results__option--highlighted[aria-selected] {
    background-color: $custom;
    color: white;
  }
  .select2-selection--multiple {
    border: 1px solid rgba($dark, 0.3);

    .select2-selection__rendered {
      padding: 0 12px;
    }
  }
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid rgba($dark, 0.3);
}

.select2-dropdown {
  border: 1px solid rgba($dark, 0.3);
  box-shadow: 0 2px 2px rgba($dark, 0.15);
}
.select2-search--dropdown {
  padding: 7px;

  .select2-search__field {
    border: 1px solid rgba($dark, 0.3);
    outline: none !important;
  }
}

/* AUTOCOMPLETE */
.autocomplete-suggestions {
  border: 1px solid #f9f9f9;
  background: $white;
  cursor: default;
  overflow: auto;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}

.autocomplete-suggestion {
  padding: 4px 10px;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-no-suggestion {
  padding: 4px 8px;
}

.autocomplete-selected {
  background: rgba($gray-lighter,0.5);
  cursor: pointer;
}

.autocomplete-suggestions strong {
  font-weight: 600;
  color: $dark;
}

.autocomplete-group {
  padding: 2px 5px;
}

.autocomplete-group strong {
  font-weight: bold;
  font-size: 16px;
  color: $dark;
  display: block;
}


/* Form validation */
.parsley-error {
  border-color: $brand-danger !important;
}
.parsley-errors-list {
  display: none;
  margin: 0;
  padding: 0;
}
.parsley-errors-list.filled {
  display: block;
}
.parsley-errors-list > li {
  font-size: 12px;
  list-style: none;
  color: $brand-danger;
  margin-top: 5px;
}


// Timepicker
.bootstrap-timepicker-widget table td input {
  border: 1px solid rgba($dark,0.3);
  width: 35px;
}


// Datepicker
.datepicker-dropdown {
  padding: 10px !important;
}

.datepicker td, .datepicker th {
    width: 30px;
    height: 30px;
}

.datepicker table tr td.active:hover, .datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active, .datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active, .datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled], .datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.selected, .datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover{
  background-color: $custom !important;
  color: $white !important;
  background-image: none !important;
  text-shadow: none !important;
}

.datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover {
  background-color: $brand-success !important;
  color: $white !important;
  background-image: none !important;
}

.datepicker-inline {
    border: 2px solid rgba($dark,0.1);
}

//Daterange Picker

.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: $custom;
    border-color: $custom;
}
.daterangepicker .input-mini.active {
    border: 1px solid rgba($dark,0.3);
}
.daterangepicker .ranges li {
  border-radius: 2px;
  color: $dark;
  font-weight: 600;
  font-size: 12px;
}
.daterangepicker select.hourselect, .daterangepicker select.minuteselect,
.daterangepicker select.secondselect, .daterangepicker select.ampmselect{
  border: 1px solid rgba($dark,0.3);
  padding: 2px;
  width: 60px;
}
.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
  background-color: $custom;
  border: 1px solid $custom;
}



/* Wizard */
.wizard > .content {
  background: $white;
  min-height: 240px;
  padding: 20px;
  border: 1px solid $gray-lighter;
  margin-top: 10px !important;
}

.wizard > .content > .body {
  padding: 0px;
  position: relative;
  width: 100%;

  input {
    border: 1px solid darken($gray-lighter,4%);
  }

  ul > li {
    display: block;
    line-height: 30px;
  }
  label.error {
    color: $brand-danger;
    margin-left: 0;
    margin-top: 5px;
    font-size: 12px;
  }
  label {
    display: inline-block;
    margin-top: 10px;
  }
}

.wizard > .steps {

  a{
    font-size: 16px;
  }

  .number {
    font-size: inherit;
  }

  .disabled {
    a {
      background: $gray-lightest;
      color: lighten($dark,5%) !important;
      cursor: default;
      border: 1px solid darken($gray-lightest,5%);
      &:hover {
        background: darken($gray-lightest,5%);
        border: 1px solid darken($gray-lightest,10%);
      }
      &:active {
        background: darken($gray-lightest,5%);
        border: 1px solid darken($gray-lightest,10%);
      }
    }
  }
  .current {
    a {
      background: $custom;
      &:hover {
        background: $custom;
        .number {
          color: $white;
        }
      }
      &:active {
        background: $custom;
        .number {
          color: $white;
        }
      }
      .number {
        color: $white;
      }
    }
  }
  .done {
    a {
      background: $gray-lighter !important;
      color: lighten($dark,5%) !important;
    }
  }
}

.wizard > .steps a, .wizard > .steps a:hover, .wizard > .steps a:active, .wizard > .content {
  border-radius: 2px;
}

.wizard > .actions {
  margin-top: 15px !important;

  a {
    background: $custom;
    border-radius: 2px !important;
    color: $white;

    &:hover {
      background: darken($custom,15%);
      color: $white;
    }
    &:active {
      background: darken($custom,15%);
      color: $white;
    }
  }
  .disabled {
    a {
      background: $white;
      color: $dark;
      cursor: default;
      border: 1px solid #eaeaea;
      &:hover {
        background: $gray-lightest;
        color: $dark;
        cursor: default;
        border: 1px solid #eaeaea;
      }
      &:active {
        background: $gray-lightest;
        color: $dark;
        cursor: default;
        border: 1px solid #eaeaea;
      }
    }
  }
}

.wizard.vertical > .content {
  margin-top: 0px !important;
}

@media (max-width: 560px) {
  .wizard.vertical > .steps,.wizard.vertical > .content {
    width: 100%;
  }
}

//Form Uploads

.jFiler-input-dragDrop {
  width: 100%;
  background-color: #fafafa;
}

.jFiler-theme-default .jFiler-input {
  width: 100%;
}
.jFiler-theme-default .jFiler-input-button {
  background-color: $custom;
  background-image: none !important;
  color: $white;
  border: 1px solid $custom !important;
}