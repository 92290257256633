/* =============
   Timeline
============= */
.timeline {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  margin-bottom: 50px;
  position: relative;
  table-layout: fixed;
  width: 100%;

  .time-show {
    margin-bottom: 30px;
    margin-right: -75px;
    margin-top: 30px;
    position: relative;
    a {
      color: $white;
    }
  }
  &:before {
    background-color: rgba($gray-light, 0.3);
    bottom: 0px;
    content: "";
    left: 50%;
    position: absolute;
    top: 30px;
    width: 1px;
    z-index: 0;
  }
  .timeline-icon {
    -webkit-border-radius: 50%;
    background: $gray-light;
    border-radius: 50%;
    color: $white;
    display: block;
    height: 20px;
    left: -54px;
    margin-top: -10px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 20px;
    i {
      color: $body-bg;
      font-size: 13px;
      margin-top: 4px;
      position: absolute;
      left: 4px;
    }
  }
  .time-icon {
    &:before {
      font-size: 16px;
      margin-top: 5px;
    }
  }

}

h3.timeline-title {
  color: $gray-lighter;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 5px;
  text-transform: uppercase;
}

.timeline-item {
  display: table-row;
  &:before {
    content: "";
    display: block;
    width: 50%;
  }
  .timeline-desk {
    .arrow {
      border-bottom: 12px solid transparent;
      border-right: 12px solid $white !important;
      border-top: 12px solid transparent;
      display: block;
      height: 0;
      left: -12px;
      margin-top: -12px;
      position: absolute;
      top: 50%;
      width: 0;
    }
    .timeline-box {
      padding: 20px;
    }
  }
  .timeline-date {
    margin-bottom: 10px;
  }
}

.timeline-item.alt {
  &:after {
    content: "";
    display: block;
    width: 50%;
  }
  .timeline-desk {
    .arrow-alt {
      border-bottom: 12px solid transparent;
      border-left: 12px solid $white !important;
      border-top: 12px solid transparent;
      display: block;
      height: 0;
      left: auto;
      margin-top: -12px;
      position: absolute;
      right: -12px;
      top: 50%;
      width: 0;
    }
    .album {
      float: right;
      margin-top: 20px;
      a {
        float: right;
        margin-left: 5px;
      }
    }
  }
  .timeline-icon {
    left: auto;
    right: -56px;
  }
  &:before {
    display: none;
  }
  .panel {
    margin-left: 0;
    margin-right: 45px;
  }
  h4 {
    text-align: right;
  }
  p {
    text-align: right;
  }
  .timeline-date {
    text-align: right;
  }
}

.timeline-desk {
  display: table-cell;
  vertical-align: top;
  width: 50%;
  h4 {
    font-size: 16px;
    font-weight: 300;
    margin: 0;
  }
  .panel {
    background: $white;
    display: block;
    margin-bottom: 5px;
    margin-left: 45px;
    position: relative;
    text-align: left;
    box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  }
  h5 {
    span {
      color: $gray-lighter;
      display: block;
      font-size: 12px;
      margin-bottom: 4px;
    }
  }
  p {
    color: #999999;
    font-size: 14px;
    margin-bottom: 0;
  }
  .album {
    margin-top: 12px;
    a {
      float: left;
      margin-right: 5px;
    }

    img {
      height: 36px;
      width: auto;
      border-radius: 3px;
    }
  }
  .notification {
    background: none repeat scroll 0 0 $white;
    margin-top: 20px;
    padding: 8px;
  }
}

/* ===========
   Timeline-2
 =============*/
.timeline-2 {
  border-left: 2px solid $custom;
  position: relative;
  .time-item {
    &:after {
      background-color: $white;
      border-color: $custom;
      border-radius: 10px;
      border-style: solid;
      border-width: 2px;
      bottom: 0;
      content: '';
      height: 10px;
      left: 0;
      margin-left: -6px;
      position: absolute;
      top: 5px;
      width: 10px;
    }
  }
}

.time-item {
  border-color: #dee5e7;
  padding-bottom: 10px;
  position: relative;
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    background-color: $white;
    border-color: $custom;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    bottom: 0;
    content: '';
    height: 14px;
    left: 0;
    margin-left: -8px;
    position: absolute;
    top: 5px;
    width: 14px;
  }
}

.time-item-item {
  &:after {
    content: " ";
    display: table;
  }
}

.item-info {
  margin-bottom: 15px;
  margin-left: 15px;
  p {
    font-size: 13px;
  }
}

// Rating
.rating-md {
  i {
    font-size: 18px;
  }
}

.rating-lg {
  i {
    font-size: 24px;
  }
}

/* =============
   Pricing
============= */
.pricing-plan {
  padding-bottom: 50px;
}

.price_card {
  -moz-border-radius: 5px;
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 5px;
  background: $white;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  padding-bottom: 20px;
  position: relative;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.price_card.active {
  margin: 0 -21px 0 -21px;
  padding-bottom: 30px;
  box-shadow: 0 0 54px 0 rgba(0, 0, 0, 0.09), 0 1px 0 0 rgba(0, 0, 0, 0.05);
  z-index: 99;

  .pricing-header {
    padding: 20px;
  }
}

.price_card .name {
  display: block;
  font-size: 16px;
  font-weight: 700;
  padding: 0 0 30px;
  text-align: center;
  width: 100%;
}

.price_card button {
  margin-top: 20px;
}

.pricing-header {
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #ffffff;
}

.price {
  display: block;
  font-size: 48px;
  font-weight: 300;
  padding: 30px 0 10px;
  text-align: center;
  width: 100%;
}

.price-features {
  color: #8a8a8a;
  list-style: none;
  margin: 0;
  padding-left: 0;
  padding-top: 20px;
  text-align: center;
}

.price-features li {
  padding: 15px;
}

.pricing-item {
  margin: 0 0 30px;
  position: relative;
  text-align: center;
}

.pricing-item-inner {
  -moz-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -ms-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  border-radius: 7px;
  border: 2px solid rgba(151, 160, 175, 0.2);
  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  vertical-align: middle;
}

.pricing-wrap {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  padding: 30px 20px;
  position: relative;
  text-align: center;
}

.pricing-icon {
  font-size: 32px;
  padding-bottom: 5px;
  padding-top: 10px;
  position: relative;
  z-index: 1;
}

.pricing-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 40px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}

.pr-list {
  color: #97a0af;
  font-size: 12px;
  font-weight: 400;
  margin: 0 -20px 30px;
  padding: 0;
  text-transform: uppercase;
}

.pr-list li {
  list-style: none;
  padding: 12px 20px;
}

.pricing-num {
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
}

.pricing-num sup {
  font-size: 18px;
  font-weight: 400;
  left: -3px;
  margin-left: -7px;
  position: relative;
  top: -20px;
}

.pr-per {
  color: #97a0af;
  font-size: 12px;
}

.pr-button {
  margin-top: 30px;
}

/* =============
   Gallery
============= */
.portfolioFilter a {
  transition: all 0.3s ease-out;
  color: $dark;
  font-weight: 600;
  padding: 5px 10px;
  display: inline-block;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;

  &:hover {
    color: $brand-success;
  }
}

.portfolioFilter a.current {
  color: $white;
  background-color: $brand-success;
}

.thumb {
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  width: 100%;
  overflow: hidden;

  a {
    display: block;
    background-color: darken($dark, 20%);
    border-radius: 3px;
  }

  &:hover {
    .thumb-img {
      overflow: hidden;
      position: relative;
      -webkit-transform: scale(1.05);
      -moz-transform: scale(1.05);
      -o-transform: scale(1.05);
      -ms-transform: scale(1.05);
      transform: scale(1.05);
      opacity: 0.7;
    }
  }

  .thumb-img {
    border-radius: 2px;
    overflow: hidden;
    width: 100%;
    transition: all 0.2s ease-out;
  }
  .gal-detail {
    padding: 10px;
    position: relative;
    background-color: $white;

    h4 {
      font-weight: 600;
      font-size: 16px;
      padding: 0 5px;
    }

    p {
      padding: 0 5px;
      font-size: 13px;
    }
  }
}

/* =============
   Print css
============= */
@media print {
  .content-page {
    margin-left: 0 !important;
  }
  .page-title-box,
  .topbar-left,
  .hidden-print,
  .breadcrumb,
  .page-title,
  .footer, .navbar-custom, .left.side-menu {
    display: none !important;
    margin: 0px;
    padding: 0px;
  }
  .left,
  .right-bar {
    display: none;
  }
  .content {
    margin-top: 0px !important;
    padding-top: 0px;
  }
}

/* =============
   Maintenance
============= */
.maintenance-icon {
  height: 220px;
  width: 220px;
  margin: 0 auto;
}

.line1 {
  opacity: 0;
  animation: fadeInLeft both 1s 0.4s, coding1 ease 6s 4s infinite;
}

.line2 {
  opacity: 0;
  animation: fadeInLeft both 1s 0.6s, coding2 ease 6s 4s infinite;
}

.line3 {
  opacity: 0;
  animation: fadeInLeft both 1s 0.8s, coding3 ease 6s 4s infinite;
}

.line4 {
  opacity: 0;
  animation: fadeInLeft both 1s 1.0s, coding4 ease 6s 4s infinite;
}

.line5 {
  opacity: 0;
  animation: fadeInLeft both 1s 1.2s, coding5 ease 6s 4s infinite;
}

.line6 {
  opacity: 0;
  animation: fadeInLeft both 1s 1.4s, coding6 ease 6s 4s infinite;
}

.line7 {
  opacity: 0;
  animation: fadeInLeft both 1s 1.6s, coding6 ease 6s 4s infinite;
}

@keyframes coding1 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  14% {
    transform: translate(0, -10px);
    opacity: 0;
  }
  15% {
    transform: translate(0, 45px);
  }
  30% {
    transform: translate(0, 40px);
    opacity: 1;
  }
  45% {
    transform: translate(0, 30px);
  }
  60% {
    transform: translate(0, 20px);
  }
  75% {
    transform: translate(0, 10px);
  }
  90% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes coding2 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  15% {
    transform: translate(0, -5px);
    opacity: 1;
  }
  29% {
    transform: translate(0, -10px);
    opacity: 0;
  }
  30% {
    transform: translate(0, 40px);
  }
  45% {
    transform: translate(0, 30px);
    opacity: 1;
  }
  60% {
    transform: translate(0, 20px);
  }
  75% {
    transform: translate(0, 10px);
  }
  90% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes coding3 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  15% {
    transform: translate(0, -5px);
  }
  30% {
    transform: translate(0, -10px);
    opacity: 1;
  }
  44% {
    transform: translate(0, -20px);
    opacity: 0;
  }
  45% {
    transform: translate(0, 30px);
  }
  60% {
    transform: translate(0, 20px);
    opacity: 1;
  }
  75% {
    transform: translate(0, 10px);
  }
  90% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes coding4 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  15% {
    transform: translate(0, -5px);
  }
  30% {
    transform: translate(0, -10px);
  }
  45% {
    transform: translate(0, -20px);
    opacity: 1;
  }
  59% {
    transform: translate(0, -30px);
    opacity: 0;
  }
  60% {
    transform: translate(0, 20px);
  }
  75% {
    transform: translate(0, 10px);
    opacity: 1;
  }
  90% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes coding5 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  15% {
    transform: translate(0, -5px);
  }
  30% {
    transform: translate(0, -10px);
  }
  45% {
    transform: translate(0, -20px);
  }
  60% {
    transform: translate(0, -30px);
    opacity: 1;
  }
  74% {
    transform: translate(0, -40px);
    opacity: 0;
  }
  75% {
    transform: translate(0, 10px);
  }
  90% {
    transform: translate(0, 5px);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes coding6 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  15% {
    transform: translate(0, -5px);
  }
  30% {
    transform: translate(0, -10px);
  }
  45% {
    transform: translate(0, -20px);
  }
  60% {
    transform: translate(0, -30px);
  }
  75% {
    transform: translate(0, -40px);
    opacity: 1;
  }
  89% {
    transform: translate(0, -50px);
    opacity: 0;
  }
  90% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

/* =============
   Count Down
============= */
.count-down-page {
  max-width: 1170px;
  margin: 50px auto;
}
#count-down {
  margin-top: 50px;

  .clock-presenter {
    height: 140px;
    line-height: 30px;
    padding: 0 30px;
    text-align: center;

    .digit {
      margin-top: 20px;
      font-size: 60px;
      font-weight: 700;
      line-height: 60px;
      height: 60px;
      display: inline-block;
      overflow: hidden;
      text-align: center;
      position: relative;
      cursor: default;
    }

    .note {
      position: relative;
      bottom: 0;
      padding-top: 5px;
      cursor: default;
      font-size: 16px;
      color: $brand-success;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 992px) {
  #count-down {
    .clock-presenter .digit {
      font-size: 42px;
    }
  }

}

@media (max-width: 767px) {
  #count-down {
    .clock-presenter {
      width: 50%;
      float: left;
    }

    .clock-presenter .digit {
      font-size: 36px;
    }

    .hours_dash {
      border-right: none;
    }
  }
}

.diamond {
  margin: 50px auto;
  height: 90px;
  width: 120px;

  &:after {
    content: "";
    position: absolute;
    height: 14px;
    width: 44px;
    background: rgba($dark,.1);
    border-radius: 50%;
    margin-top: 0;
    margin-left: 38px;
    z-index: 11;
  }
  .top {
    height: 30px;
    border-left: 27px solid transparent;
    border-right: 27px solid transparent;
    border-bottom: 24px solid #48CFAD;
    &:after {
      content: "";
      position: absolute;
      height: 24px;
      width: 32px;
      margin-top: 6px;
      margin-left: 47px;
      background: #37BC9B;
      -ms-transform: skew(30deg, 20deg);
      -webkit-transform: skew(30deg, 20deg);
      transform: skew(48deg);
    }
    &:before {
      content: "";
      position: absolute;
      height: 24px;
      width: 32px;
      margin-top: 7px;
      margin-left: -13px;
      background: #37BC9B;
      -ms-transform: skew(30deg, 20deg);
      -webkit-transform: skew(30deg, 20deg);
      transform: skew(-48deg);
    }
  }
  .bot {
    height: 60px;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-top: 60px solid #48CFAD;
    &:before {
      content: "";
      position: absolute;
      height: 60px;
      margin-top: -60px;
      margin-left: -27px;
      border-left: 27px solid transparent;
      border-right: 26px solid transparent;
      border-top: 60px solid #37BC9B;
    }
  }
}